import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { withTheme } from 'styled-components';

function Project(props) {


	return(
	<div className="card horizontal">
	    <div className="card-image">
	    <div className="overlay"></div>

	     {props.theme.mode === 'dark' ? (<img src={props.image}/>) : (<img src={props.lightimage}/>)}
	    </div>
	    <div className="card-stacked">
	      <div className="card-content">

	      	<span>{ props.date && <p className="projectDate">// {props.date}</p>}</span>
	        <span className="card-title">{ props.title && <h3>{props.title}</h3> }</span>
	        <span>{ props.stack && <p>Stack: {props.stack}</p> }
	          </span>

	           <span>{ props.text && <p>{props.text}</p> }
	          </span>
	      </div>
	      <div className="card-action">
	        <span className="linkStyle"><a href={props.link}>{props.linkText}</a></span>
	      </div>
	    </div>
	  </div>
	);
}


export default withTheme(Project)