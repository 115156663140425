import React from "react"
import { Link } from "gatsby"
import { Container, Row, Col } from 'react-grid-system';
import Layout from "../components/layout"
import Spacer from "../components/Spacer"
import Title from "../components/Title"
import Image from "../components/image"
import SEO from "../components/seo"


import Project from "../components/Project"
import useTheme from "../components/useTheme";

import ImageOne from "../images/header-cng.jpg"
import ImageTwo from "../images/header-rrl.jpg"
import ImageThree from "../images/header-ebc.jpg"
import ImageFour from "../images/header-greygates.jpg"
import ImageFive from "../images/rh-dark.jpg"
import ImageFiveLight from "../images/rh-light.jpg"



const ProjectsPage = () => (


  <Layout>
    <SEO title="Projects" />
   {/* <h2>Snap!</h2>*/}
<Container style={{paddingTop:`60px`}}>
  

  <Title title="Projects"/>
  <hr/>
 
  <Row>
    
   <Col sm={12} md={6} lg={4}>
        <Project 
        title="roberthammond.dev" 
        stack="React, Gatsby, SASS, Netlify" 
        text="Key features: All the best bits of Gatsby, Netlify watches git repo for new deploys. Theme toggle with session storage." 
        image={ImageFive}
        lightimage={ImageFiveLight}  
        linkText="Work in progress" 
        link="#" 
        date="Ongoing"  
        />
    </Col>

    <Col sm={12} md={6} lg={4}>
        <Project 
        title="Richmond Residential" 
        stack="React, Contentful CMS, Netlify" 
        text="Key features: Continuous deployment, property search, property tagging (let, sold, etc), featured properties." 
        image={ImageTwo}
        lightimage={ImageTwo}  
        linkText="View site" 
        link="https://richmondresidential.co.uk" 
        date="2020" 
        />
    </Col>
    <Col sm={12} md={6} lg={4}>
        <Project 
        title="CNG Trade Services" 
        stack="SASS, jQuery, Vanilla JS, PHP, MySQL" 
        text="Key features: User specific downloads, careers posts, case studies posts." 
        image={ImageOne} 
        lightimage={ImageOne}
        linkText="Under construction" 
        link="#" 
        date="In prog"  
        />
    </Col>
    <Col sm={12} md={6} lg={4}>
        <Project 
        title="Greygates Nursery" 
        stack="HTML, SASS" 
        text="Simple, responsive landing page." 
        image={ImageFour} 
        lightimage={ImageFour} 
        linkText="View site" 
        link="http://greygatesnursery.co.uk/" 
        date="2018" 
        />
    </Col>
     <Col sm={12} md={6} lg={4}>
        <Project 
        title="East Beach Cafe" 
        stack="SASS, jQuery, Vanilla JS, Woocommerce" 
        text="Key features: Woocommerce based Shop, custom emails per product." 
        image={ImageThree}
        lightimage={ImageThree}  
        linkText="View site" 
        link="https://eastbeachcafe.co.uk/"  
        date="2017"   
        />
    </Col>
    
    
  </Row>

</Container>
  
    {/*<div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      <Image />
    </div>*/}
   {/*<AniLink cover to="page-2" bg="#111111">Go to page 2</AniLink>*/}
  </Layout>
)

export default ProjectsPage
