import PropTypes from "prop-types"
import React from "react"

const Title = (props) => (

     <h5 className="projectHead">{props.title}</h5>
     
 

)


export default Title
